import { cn } from '@/utils/cn';
import Image from 'next/image';
import type { ComponentProps } from 'react';
import { forwardRef, useState, cloneElement } from 'react';

import { extractSizeFromClassName, getBgColor } from '../common';

export const AvatarPropsShape = ['square', 'circle'] as const;
export type AvatarProps = Omit<ComponentProps<'div'>, 'onError'> & {
  shape?: (typeof AvatarPropsShape)[number];
  srcs: (string | null | undefined)[];
  alt: string;
  coloredBackground?: boolean;
  onError?: (index: number, s: string) => void;
  placeholder?: JSX.Element;
};

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  (
    {
      className,
      alt,
      srcs,
      coloredBackground,
      style,
      onError,
      placeholder,
      shape = 'square',
      ...props
    },
    ref,
  ) => {
    const filteredSrcs = srcs.filter((src) => src !== null && src !== undefined);
    const [srcIndex, setSrcIndex] = useState(0);
    const initials = alt
      ?.split(' ')
      .map((a) => a[0]?.toLocaleUpperCase())
      .join('')
      .toUpperCase();

    const _onError = () => {
      const src = filteredSrcs[srcIndex];
      if (src) onError?.(srcIndex, src);
      setSrcIndex((i) => i + 1);
    };

    const src = filteredSrcs[srcIndex];
    const bgColor =
      src == null && coloredBackground && initials != null ? getBgColor(initials) : undefined;

    const size = extractSizeFromClassName(className);

    return (
      <div
        ref={ref}
        className={cn(
          'flex aspect-square h-full shrink-0 items-center justify-center overflow-hidden rounded-md font-medium',
          'bg-slate-200 text-slate-950 dark:bg-slate-700 dark:text-slate-50',
          { 'rounded-full': shape === 'circle', 'rounded-md': shape === 'square' },
          {
            'text-4xs': size != null && size <= 8,
            'text-3xs': size != null && size > 8 && size <= 14,
            'text-2xs': size != null && size > 14 && size <= 20,
            'text-xs': size != null && size > 20 && size <= 32,
            'text-base': size != null && size > 32 && size <= 40,
            'text-xl': size != null && size > 40 && size <= 64,
          },
          className,
        )}
        style={{ background: bgColor, color: bgColor ? '#fff' : undefined, ...style }}
        {...props}
      >
        {src && (
          <Image
            alt={alt}
            src={src}
            width={size}
            height={size}
            fill={!size}
            onError={_onError}
            className="h-full w-full object-cover"
          />
        )}
        {!src && (
          <>
            {placeholder != null && cloneElement(placeholder, { className: 'size-2/3' })}
            {!placeholder && <span>{initials}</span>}
          </>
        )}
      </div>
    );
  },
);
Avatar.displayName = 'Avatar';

export default Avatar;
