import React from 'react';
import { Popup } from '../Popup/Popup';
import styles from './VimeoPopup.module.scss';

interface VimeoPopupProps {
  vimeoId: string;
  setShowPopup: (show: boolean) => void;
}

export const VimeoPopup: React.FC<VimeoPopupProps> = ({ vimeoId, setShowPopup }) => {
  const url = `https://player.vimeo.com/video/${vimeoId}?h=95aeaf3afc`;

  return (
    <Popup
      onClose={() => setShowPopup(false)}
      className={styles.popupContent}
      popupContentClassName={styles.popupContentBody}
    >
      <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
        <iframe
          src={url}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          title={`Vimeo video ${vimeoId}`}
        ></iframe>
      </div>
    </Popup>
  );
};

export default VimeoPopup;
