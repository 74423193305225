import {
  FloatingPortal,
  autoPlacement,
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
  useHover,
  useInteractions,
  useRole,
  type Placement,
} from '@floating-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState, type CSSProperties } from 'react';

export type TooltipLabel = React.ReactNode | undefined;

export interface Props {
  label: TooltipLabel;
  placement?: Placement;
  children: JSX.Element;
  background?: string;
  color?: string;
  boxShadow?: string;
  autoPlace?: boolean;
  maxWidth?: number;
}

export const DeleteTooltip = (props: Props) => (
  <Tooltip {...props} background="hsla(0, 65%, 61%, 0.9)" />
);

export const Tooltip: React.FC<Props> = ({
  children,
  label,
  background,
  color,
  boxShadow,
  placement = 'top',
  autoPlace = true,
  maxWidth = 400,
}) => {
  const [open, setOpen] = useState(false);
  const { x, y, strategy, context, refs } = useFloating({
    placement,
    open,
    onOpenChange(open) {
      setOpen(open);
    },
    middleware: [
      offset(5),
      shift({
        padding: 5,
      }),
      autoPlace ? autoPlacement({ padding: 8 }) : flip({ padding: 8 }),
    ],
    whileElementsMounted: autoUpdate,
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { mouseOnly: true }),
    useRole(context, { role: 'tooltip' }),
  ]);

  const _background = background ?? 'hsla(0, 0%, 10%, 0.9)';

  const style = {
    position: strategy,
    top: y ?? 0,
    left: x ?? 0,
    borderRadius: 4,
    zIndex: 1000000,
    fontWeight: 'normal',
    transform: 'unset',
    opacity: 1,
    transition: 'opacity 200ms',
    padding: '4px 6px',
    fontSize: 14,
    color: color ?? '#fff',
    maxWidth: maxWidth,
    background: background ?? 'hsla(0, 0%, 10%, 0.9)',
    boxShadow: boxShadow ?? `0px 2px 4px ${_background}`,
    pointerEvents: 'none',
    '--kbd-color': 'hsla(0, 0%, 100%, 0.55)',
    '--kbd-bg-color': 'transparent',
  } as CSSProperties;
  return (
    <>
      {React.cloneElement(children, getReferenceProps({ ref: refs.setReference }))}
      {label != null && (
        <FloatingPortal id="tooltip">
          <AnimatePresence>
            {open && (
              <motion.div
                initial={{ opacity: 0, scale: 0.85 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0 }}
                transition={{ type: 'spring', damping: 20, stiffness: 300 }}
                {...getFloatingProps({ ref: refs.setFloating, style: style })}
              >
                {label}
              </motion.div>
            )}
          </AnimatePresence>
        </FloatingPortal>
      )}
    </>
  );
};
