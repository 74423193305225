'use client';

import React from 'react';
import { G2crowd } from '~/app/_components/ToneIcons';
import { Avatar } from '@/twComponents/Avatar';
import CenteredSection from '@/pageComponents/CenteredSection';

interface ReviewUser {
  name?: string;
  title?: string;
  url?: string;
}
interface ReviewModel {
  title?: string | JSX.Element;
  children: string | JSX.Element;
  user?: ReviewUser;
}

const reviewsCol1: ReviewModel[] = [
  {
    children: `Poseidon is the easiest tool I've used to reach the right people at scale. It's also much more secure than other tools out there. Ash and his team have been a dream to work with, offering to go above and beyond to make sure that we're targetting the right people and have more information than we'd have with any other vendor.

  I've used four of five other tools in the past, and Poseidon is by far the best one I've used.`,
    user: {
      name: 'Haseeb',
      title: 'Engineering Recruiting',
      url: 'https://images.g2crowd.com/uploads/avatar/image/1921895/thumb_square_b18026d2ce321cc8f198723eb8b699f9.jpeg',
    },
  },
  {
    user: {
      name: 'Evan S',
      title: 'Electronic Materials Officer',
      url: 'https://images.g2crowd.com/uploads/avatar/image/1771748/thumb_square_920c983605da43aa7377f14072f3c3c4.jpeg',
    },
    title: 'Fantastic tool for networking and outreach.',
    children:
      "One of Poseidon's strongest features is the shared LinkedIn connection list. Instead of waiting for lead lists from my team, I am able to search their entire list of connections and identify potential leads. Poseidon also offers an incredibly user-friendly interface.",
  },
  {
    user: {
      name: 'Patrice L',
      title: 'Account Executive',
      url: 'https://images.g2crowd.com/uploads/avatar/image/1771970/thumb_square_5c0442f270b2a3493a3eca464da0b726.jpeg',
    },
    title: 'Elite sales enablement tool',
    children:
      "The UI/UX of Poseidon is far better than other products on the market. I've used everything from outreach, Hubspot, Salesloft. Poseidon feels the most modern and intuitive. Not having to bounce between multiple tools like Zoominfo etc, and to have that integrated directly into the platform is a huge time saver. The notes, account notes, and contact adjusting is far superior to what outreach has.",
  },
  {
    user: {
      name: 'Verified User in Computer Software',
      title: 'Small Business',
      url: 'https://www.g2.com/assets/icons/anonymous-avatar-purple-4ae1032bdb50ee5682003170c8184aee790d25958bd397abbd384ba52c596a7b.svg',
    },
    title: 'Efficient way to lead SDR teams to value',
    children:
      'Simplifies the visibility teams have into interactions prospects are having with outbound collateral, push notifications for engagement // Structured in a way that is conducive to training others on best practices (templates, intuitive interface that can be helpful for positive habit-forming, etc.) // When multithreading outreach to a parent company, interactions with all prospects within the company are easy to visualize alongside all historic engagement // Less screen hopping, less manual work // Tagging ability helps with segmenting leads by your preferred method',
  },
];
const reviewsCol2: ReviewModel[] = [
  {
    user: {
      name: 'Lukas M',
      title: 'Founder & CEO, Sales Leader',
      url: 'https://useposeidon.s3.amazonaws.com/lukas.jpg',
    },
    title: 'Best Sales Outbound Tooling',
    children:
      "- It's easy to keep track of the leads you reached out to - Great overview of who was contacted - Decrases the amount of manual work - Automate your Outbound strategy end to end",
  },
  {
    user: {
      name: 'Andrew S',
      title: 'Entrepreneur',
      url: 'https://images.g2crowd.com/uploads/avatar/image/1762904/thumb_square_d44b464aaf31cab473d32282a833da30.jpg',
    },
    title: 'This tool gave me enough confidence to start my own company',
    children:
      "1. The makers of the tool keep it simple. Too many tools try to sell you into their method which is always too much. If you have a clear point of view of how you want to sell, what you want out of a tool, and not a bunch of extra, this is a good choice. 2. Of the automation tools out there, this one gives you the satisfaction of actually doing the work on screen. It doesn't just do it in the background in a black box. I didn't realize how valuable this would be until I saw it. 3. The founder and engineers are extremely accessible. Service-wise, I feel heard as a customer and many times the product team has gone out of their way to make little things possible for me. Review collected by and hosted on G2.com.",
  },
  {
    user: {
      name: 'Jason A',
      title: 'Vice President, Wealth Advisor',
      url: 'https://images.g2crowd.com/uploads/avatar/image/1840268/thumb_square_f7c935f901dec91f76a0095ce98b63f5.jpeg',
    },
    title: 'Next level!!',
    children:
      "As a self-described dinosaur who remembers when prospecting was done with a phone and index cards, Poseidon has taken me to a different level in outreach. Easy to use, great support (hat tip Ash); if you're wondering how to advance your sales efforts in a digital world, contact Poseidon today.",
  },
  {
    user: {
      name: 'Bilal A',
      title: 'CEO',
      url: 'https://www.g2.com/assets/icons/anonymous-avatar-purple-4ae1032bdb50ee5682003170c8184aee790d25958bd397abbd384ba52c596a7b.svg',
    },
    title: 'Supercharge your prospecting by going multichannel',
    children:
      "I love that enables touching buyers at different points in the journey, and across the different channels that they may be communicating in. The modern buyers' journey is complicated and multi-faceted, you need a sales co-pilot that understands that and works with it.",
  },
  {
    user: {
      name: 'Neil H',
      title: 'Vice President of Sales and Alliances',
      url: 'https://images.g2crowd.com/uploads/avatar/image/1704113/thumb_square_741ce98d0cc7793c943f2e5c9482f525.jpeg',
    },
    title:
      "One tool for your sales outreach so that you don't have bounce around to different tools",
    children:
      "Like my title suggest, I am happy to find a tool that let's me focus all of my sales outreach in one place (including videos and documents)",
  },
  {
    user: {
      name: 'Megan K.',
      title: 'VP of Strategic Sales & Marketing',
      url: 'https://images.g2crowd.com/uploads/avatar/image/333153/thumb_square_f825fd33cc8a6531145dc6d91b0ac8b2.jpeg',
    },
    title: 'The next greatest prospecting tool',
    children:
      'Simpler tool to align sales & marketing Less complicated and cumbersome than other tools Easy for new teammates to get ramped up with all the research, tools, outreach and materials in one place Easy to act on intent - know when people are viewing things Account data also available right in the plugin. Can see which employees have already been cadenced - who touched it last, and how we reached out to them in the past.',
  },
];

const reviewsCol3: ReviewModel[] = [
  {
    user: {
      name: 'Blair A',
      title: 'Cofounder and Producer',
      url: 'https://images.g2crowd.com/uploads/avatar/image/1758966/thumb_square_ec78c070d20a25e89a672c6888752173.jpg',
    },
    title: 'Personalization at scale across channels = great multiplier to any sales team',
    children:
      "The ability to create media-rich sharable/trackable links is excellent. It's like martech built directly into a sales enablement platform. It's allowed me and my team to ensure all our sales materials are highly tailored per account and ICP. It's also turned our classic sales folk into youtubers of sort. Creating mutlichannel sequences is also far easier than other platforms I've used. The analytics is great and the support is also fantastic. Founder-level engagement with customer success issues is luxurious. Felt like my product feedback was truly heard and they were willing to add new features to the roadmap.",
  },
  {
    user: {
      name: 'Cynthia H.',
      title: 'Senior Sales Director',
      url: 'https://www.g2.com/assets/icons/anonymous-avatar-purple-4ae1032bdb50ee5682003170c8184aee790d25958bd397abbd384ba52c596a7b.svg',
    },
    title: 'Great tool for multichannel prospecting!',
    children:
      "Love that it has a built-in Dialer. Also, love the fact that it is the only tool that provides multichannel prospecting! FINALLY but not least, being able to search through your library of messages to find what's worked.",
  },
  {
    user: {
      name: 'Mauricio S.',
      title: 'Enterprise Account Executive',
      url: 'https://images.g2crowd.com/uploads/avatar/image/585484/thumb_square_28cdc1a5ad425a27df6b7630028bc134.jpg',
    },
    title: 'Wasn´t expecting to be this useful',
    children:
      'It allows me to have a CRM for my Linkedin and ease the challenge of messaging people first. I would like to have templates made by me and created by them. Im not sure if I can VOIP my phone, but that would be pretty rad.',
  },
  {
    user: {
      name: 'Verified User in Computer Software',
      url: 'https://www.g2.com/assets/icons/anonymous-avatar-purple-4ae1032bdb50ee5682003170c8184aee790d25958bd397abbd384ba52c596a7b.svg',
    },
    title: 'Poseidon is excellent for managing key relationships',
    children:
      "I’ve been using Poseidon since the early days and seeing how fast it's improving is bonkers. Of course it’s effective as an outbound Sales tool, but what I like most about it is that I can use it to add notes directly on social profiles about potential investors, partners, and developers that I want to hire, or people that I want to partner with. Then I can come back to these profiles later on and just remind myself to do something with that person, even if it's just to send them an email.",
  },
];

export const ReviewsSection = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const length = reviewsCol1.length + reviewsCol2.length + reviewsCol3.length;

  React.useEffect(() => {
    if (ref.current) {
      const timeout = setInterval(() => {
        if (ref.current) {
          const itemWidth = ref.current.scrollWidth / length;
          ref.current.scrollLeft = (ref.current.scrollLeft + itemWidth) % ref.current.scrollWidth;
        }
      }, 3000);

      return () => {
        clearInterval(timeout);
      };
    }
  }, [ref, length]);

  return (
    <section className="py-10 bg-gradient-to-b from-[#221b4e] to-[#22182e] flex flex-col items-center">
      <CenteredSection className="w-full grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3 relative">
        <div className="flex flex-col col-span-1 gap-2 md:gap-4">
          {reviewsCol1.map((review, i) => (
            <Review key={i} review={review} />
          ))}
        </div>
        <div className="flex flex-col col-span-1 gap-2 md:gap-4 lg:col-span-2">
          {reviewsCol2.map((review, i) => (
            <Review key={i} review={review} />
          ))}
        </div>
        <div className="flex flex-col col-span-1 gap-2 md:gap-4">
          {reviewsCol3.map((review, i) => (
            <Review key={i} review={review} />
          ))}
        </div>
      </CenteredSection>
    </section>
  );
};

const Review: React.FC<{ review: ReviewModel }> = ({ review }) => {
  return (
    <div className="bg-white bg-opacity-5 border border-white border-opacity-10 rounded-lg flex flex-col gap-2 p-5 relative text-white font-light">
      <div className="flex items-center mb-2">
        <G2crowd className="size-6 text-white opacity-60" />
      </div>
      <div className="text-sm font-Rajdhani font-bold">{review.title}</div>
      <div className="text-sm">{review.children}</div>

      <div className="flex items-start gap-3 w-full">
        <Avatar
          shape="circle"
          alt={review?.user?.name || ''}
          srcs={[review.user?.url]}
          className="size-10"
        />
        <div className="flex flex-col text-white">
          <div className="font-Rajdhani font-bold">{review.user?.name}</div>
          <div className="opacity-50 text-sm">{review.user?.title}</div>
        </div>
      </div>
    </div>
  );
};
