'use client';

import clsx from 'clsx';
import Link, { type LinkProps } from 'next/link';
import React, { type ReactNode } from 'react';
import type { InputSize } from '../Input/types';
import { Tooltip, type TooltipLabel } from '../Tooltip';
import styles from './Button.module.scss';

export type ButtonType = 'primary' | 'dangerous' | 'submit' | 'submitPrimary' | 'text' | 'largeCTA';
export type BorderType = 'solid' | 'dashed';

interface LinkButtonProps extends Omit<LinkProps, 'size'> {
  icon?: JSX.Element;
  rightIcon?: JSX.Element;
  type?: ButtonType;
  className?: string;
  children?: ReactNode;
  target?: string;
  tooltip?: TooltipLabel;
  size?: InputSize;
}

export const LinkButton = React.forwardRef<HTMLAnchorElement, LinkButtonProps>(
  ({ icon, children, type, className, href, target, size, tooltip, ...props }, ref) => {
    const classList = clsx(
      styles.button,
      size == 'small' ? styles.small : undefined,
      type ? styles[type] : undefined,
      styles.hoverable,
      className,
    );

    if (tooltip) {
      return (
        <Tooltip label={tooltip}>
          <Link className={classList} href={href} target={target} ref={ref} {...props}>
            {icon != null && <div className={styles.icon}>{icon}</div>}
            {children != null && <span>{children}</span>}
          </Link>
        </Tooltip>
      );
    }

    return (
      <Link className={classList} href={href} target={target} ref={ref} {...props}>
        {icon != null && <div className={styles.icon}>{icon}</div>}
        {children != null && <span>{children}</span>}
      </Link>
    );
  },
);
LinkButton.displayName = 'LinkButton';
