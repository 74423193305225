import clsx from 'clsx';
import React, { type CSSProperties } from 'react';
import { createPortal } from 'react-dom';

import styles from './Popup.module.scss';
import usePortal from '../../../hooks/usePortal';

const PopupPortal: React.FC<React.HTMLProps<HTMLDivElement>> = ({ id, children }) => {
  const target = usePortal(id ?? 'poseidon-modal-portal');
  return createPortal(children, target);
};

export interface PopupProps extends React.HTMLProps<HTMLDivElement> {
  popupClassName?: string;
  popupContentClassName?: string;
  icon?: React.ReactNode;
  style?: CSSProperties;
  onClose: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
}

export const Popup: React.FC<PopupProps> = ({
  popupClassName,
  popupContentClassName,
  onClose,
  icon,
  children,
  style,
  className,
  ...props
}) => {
  React.useEffect(() => {
    document.body.classList.add('popupShown');
    return () => {
      document.body.classList.remove('popupShown');
    };
  }, []);

  React.useEffect(() => {
    const onClose = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose?.(e);
      }
    };
    window.addEventListener('keydown', onClose);
    return () => window.removeEventListener('keydown', onClose);
  }, []);

  return (
    <PopupPortal>
      <div className={clsx(styles.container, popupClassName)} style={style}>
        <div className={styles.background} onClick={onClose} />
        <div className={clsx(styles.content, className)} {...props}>
          {icon != null && <div className={styles.circle}>{icon}</div>}
          <div className={clsx(styles.body, popupContentClassName)}>{children}</div>
        </div>
      </div>
    </PopupPortal>
  );
};
